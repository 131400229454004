import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "firebase.config";
import { Col, Rate, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TypeStore } from "store/reducers";
import TitleBox from "components/TitleBox";
import FooterItem from "components/FooterItem";

export default function Products({items, title, maxShow = 8}: {items: any[], title: string, maxShow?: number}) {

    // const [items, setItems] = useState<any>([])
    const { themelayout } = useSelector((state: TypeStore) => state)

    // const loadMore = async () => {
    //     let q = query(collection(firestore, 'items'), where("outstanding", "==", true));

    //     getDocs(q)
    //         .then((querySnapshot) => {
    //             const newItems: any[] = [];
    //             querySnapshot.forEach((doc) => {
    //                 newItems.push({ id: doc.id, ...doc.data() } as any);
    //             });

    //             if (newItems.length > 0) {
    //                 setItems([...items, ...newItems]);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error getting documents: ', error);
    //         });
    // };

    // useEffect(() => {
    //     loadMore();
    // }, []);

    console.log('items selected', items);
    
    if(items.length === 0) return <></>
  return (
    <div style={{marginTop: 30}} className="products-custom">
    <TitleBox>
        <h1 className='text-30 f-600 mg-auto'>
           {title}
        </h1>
    </TitleBox>
    <Row gutter={[20, 20]} style={{ marginTop: 40 }} className="product_row">
            {items.slice(0, maxShow ?? 8).map((element: any) => (
                <Col xs={12} md={6}>
                    <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/chi-tiet/${element.id}`} key={element.id}>
                        <div className={`w-100 pointer box-shadow relative ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                            <div className='devvn_label_product'>
                                    <span className="sale">{
                                        (element.price && element.priceSale) ? `- ${(100 - ((element.priceSale / element.price) * 100)).toFixed(1)} %` : 'Giảm sâu'
                                    }</span>
                                </div>
                            <div className="item-frame-image">
                                <img className='img-item bdr-t-4' src={element?.listFiles?.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                {element?.listFiles?.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                            </div>

                            <div className="inf-item flex-col gap-8">
                            <Typography.Paragraph ellipsis={{rows: 2}} className="text-13 f-400 text-center">{element.name}</Typography.Paragraph>
                            <div className="flex-row flex-center gap-8 flex-wrap">
                                    <span className="text-13 f-500">{Number(element.priceSale).toLocaleString()} VNĐ</span>
                                    {element.price
                                        && <div className="flex-row flex-center relative">
                                            <span className="text-12 f-500 text-blur">{Number(element.price).toLocaleString()} VNĐ</span>
                                            <div className="line-center"></div>
                                        </div>
                                    }
                                </div>
                                <FooterItem item={element}/>

                            </div>

                        </div>
                    </Link>
                </Col>
            ))}
    </Row>
    </div>
  )
}
